<template>
  <div class="govuk-inset-text">
    <slot />
  </div>
</template>

<script>
export default {
  name: "GovInsetText"
};
</script>
